import { createAsyncThunk } from "@reduxjs/toolkit";
// @ts-ignore
import { api } from "@enerBit/base";
import {
  DocsByBrand,
  DocsByBrandAndGauge,
  DocsByModelAndBrand,
  DocsBySerialNumber,
  DocsWithoutParams,
  DocumentItem,
  DocumentsModel,
  FormLabelInput,
} from "../../../models/LibraryConsult";
import { setFileName } from "./librarySlice";
import { getAuthenticatedFile } from "../../../services/library";
import { formatDate } from "../../../helpers/library";

export const getWireTechnicalSheet = createAsyncThunk(
  "library/getWireTechnical",
  async (data: FormLabelInput, thunkApi) => {
    const url = "/inventory-documentation/wire-technical-sheets";
    const params: Record<string, string> = {};

    if (data.gauge) {
      params["gauge"] = data.gauge;
    }

    if (data.material) {
      params["material"] = data.material;
    }

    const response = await api.get<DocumentsModel>(url, { params });

    thunkApi.dispatch(setFileName("Ficha técnica Cable"));

    const tempDocs = response.data.items.map(async (item: DocumentItem) => {
      const authUrl = await getAuthenticatedFile(
        item.form_data.certificate_path
      );
      const creationDate = formatDate(item.created_at);
      const url = item.form_data.certificate_path;
      const extra = [
        { name: "Calibre", value: item.form_data.gauge },
        { name: "Material", value: item.form_data.material },
        {
          name: "Número de certificado",
          value: item.form_data.certificate_number,
        },
      ];

      return { authUrl, creationDate, url, extra };
    });

    const docs = await Promise.all(tempDocs);

    return docs;
  }
);

export const getDocsByModelAndBrand = createAsyncThunk(
  "library/getDocsByModelAndBrand",
  async (data: DocsByModelAndBrand, thunkApi) => {
    const params: Record<string, string> = {};

    if (data.model) {
      params["model"] = data.model;
    }

    if (data.brand) {
      params["brand"] = data.brand;
    }

    const response = await api.get<DocumentsModel>(data.url, { params });

    console.log(data.fileName);

    thunkApi.dispatch(setFileName(data.fileName));

    const tempDocs = response.data.items.map(async (item: DocumentItem) => {
      const authUrl = await getAuthenticatedFile(
        item.form_data.certificate_path
      );
      const creationDate = formatDate(item.created_at);
      const url = item.form_data.certificate_path;
      const extra = [
        { name: "Marca", value: item.form_data.brand },
        { name: "Modelo", value: item.form_data.model },
      ];

      if (data.option !== "technicalEquipment") {
        extra.push({
          name: "Número de certificado",
          value: item.form_data.certificate_number,
        });
      }

      return { authUrl, creationDate, url, extra };
    });

    const docs = await Promise.all(tempDocs);

    return docs;
  }
);

export const getDocsByBrand = createAsyncThunk(
  "library/getDocsByBrand",
  async (data: DocsByBrand, thunkApi) => {
    const params: Record<string, string> = {};

    if (data.brand) {
      params["brand"] = data.brand;
    }
    const response = await api.get<DocumentsModel>(data.url, { params });

    thunkApi.dispatch(setFileName(data.fileName));

    const tempDocs = response.data.items.map(async (item: DocumentItem) => {
      const authUrl = await getAuthenticatedFile(
        item.form_data.certificate_path
      );
      const creationDate = formatDate(item.created_at);
      const url = item.form_data.certificate_path;
      const extra = [
        { name: "Marca", value: item.form_data.brand },
        { name: "Modelo", value: item.form_data.model },
        {
          name: "Número de certificado",
          value: item.form_data.certificate_number,
        },
      ];

      return { authUrl, creationDate, url, extra };
    });

    const docs = await Promise.all(tempDocs);

    return docs;
  }
);

export const getDocsByBrandAndGauge = createAsyncThunk(
  "library/getDocsByBrandAndGauge",
  async (data: DocsByBrandAndGauge, thunkApi) => {
    const params: Record<string, string> = {};

    if (data.brand) {
      params["brand"] = data.brand;
    }

    if (data.gauge) {
      params["gauge"] = data.gauge;
    }

    const response = await api.get<DocumentsModel>(data.url, { params });

    thunkApi.dispatch(setFileName(data.fileName));

    const tempDocs = response.data.items.map(async (item: DocumentItem) => {
      const authUrl = await getAuthenticatedFile(
        item.form_data.certificate_path
      );
      const creationDate = formatDate(item.created_at);
      const url = item.form_data.certificate_path;
      const extra = [
        { name: "Marca", value: item.form_data.brand },
        { name: "Calibre", value: item.form_data.gauge },
        { name: "Material", value: item.form_data.material },
        {
          name: "Número de certificado",
          value: item.form_data.certificate_number,
        },
      ];

      return { authUrl, creationDate, url, extra };
    });

    const docs = await Promise.all(tempDocs);

    return docs;
  }
);

export const getDocsBySerialNumber = createAsyncThunk(
  "library/getDocsBySerialNumber",
  async (data: DocsBySerialNumber, thunkApi) => {
    const params: Record<string, string> = {};

    if (data.option === "meterCalibrationCertificate") {
      params["certificate_number"] = data.serial;
    } else {
      params["number"] = data.serial;
    }
    const response = await api.get<DocumentsModel>(data.url, { params });

    thunkApi.dispatch(setFileName(data.fileName));

    const tempDocs = response.data.items.map(async (item: DocumentItem) => {
      const authUrl = await getAuthenticatedFile(
        item.form_data.certificate_path
      );
      const creationDate = formatDate(item.created_at);
      const url = item.form_data.certificate_path;
      const extra = [{ name: "Numero serial", value: item.form_data.number }];

      return { authUrl, creationDate, url, extra };
    });

    const docs = await Promise.all(tempDocs);

    return docs;
  }
);

export const getMeterCalibrationCertificate = createAsyncThunk(
  "library/getMeterCalibrationCertificate",
  async (data: DocsBySerialNumber, thunkApi) => {
    const params: Record<string, string> = {};

    if (data.serial) {
      params["meter_serial"] = data.serial;
    }

    const response = await api.get<DocumentsModel>(data.url, { params });

    thunkApi.dispatch(setFileName(data.fileName));

    const tempCertificates = response.data.items.map(
      async (item: DocumentItem) => {
        const certificates = [];
        let quadrants = 0;
        if (
          item.form_data.active_export_import &&
          item.form_data.reactive_export_import
        ) {
          quadrants = 2;
          const temp = [
            {
              url: await getAuthenticatedFile(
                item.form_data.active_export_import.certificate_path
              ),
              name: "active_export_import",
            },
            {
              url: await getAuthenticatedFile(
                item.form_data.reactive_export_import.certificate_path
              ),
              name: "reactive_export_import",
            },
          ];
          certificates.push(temp);
        } else if (
          item.form_data.active_export &&
          item.form_data.active_import &&
          item.form_data.reactive_export &&
          item.form_data.reactive_import
        ) {
          quadrants = 4;
          const temp = [
            {
              url: await getAuthenticatedFile(
                item.form_data.active_import.certificate_path
              ),
              name: "active_import",
            },
            {
              url: await getAuthenticatedFile(
                item.form_data.active_export.certificate_path
              ),
              name: "active_export",
            },
            {
              url: await getAuthenticatedFile(
                item.form_data.reactive_import.certificate_path
              ),
              name: "reactive_import",
            },
            {
              url: await getAuthenticatedFile(
                item.form_data.reactive_export.certificate_path
              ),
              name: "reactive_export",
            },
          ];
          certificates.push(temp);
        }
        const extra = [
          { name: "Numero serial", value: item.form_data.meter_serial },
        ];

        return {
          creationDate: formatDate(item.created_at),
          files: certificates.flat(),
          quadrants,
          extra,
        };
      }
    );

    const docs = await Promise.all(tempCertificates);

    return docs;
  }
);

export const getDocsWithoutParams = createAsyncThunk(
  "library/getDocsWithoutParams",
  async (data: DocsWithoutParams, thunkApi) => {
    const response = await api.get<DocumentsModel>(data.url);

    thunkApi.dispatch(setFileName(data.fileName));

    const tempDocs = response.data.items.map(async (item: DocumentItem) => {
      const authUrl = await getAuthenticatedFile(
        item.form_data.certificate_path
      );
      const creationDate = formatDate(item.created_at);
      const url = item.form_data.certificate_path;

      return { authUrl, creationDate, url };
    });

    const docs = await Promise.all(tempDocs);

    return docs;
  }
);
